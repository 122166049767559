@import './sass/inc/variables';

//Theme Color Overrides
@import './sass/_variables.objekt360.scss';

@import './sass/mixins/mixins';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../shared/modules/bootstrap-colorpicker/src/sass/_colorpicker.scss';
@import '../../node_modules/jquery.scrollbar/jquery.scrollbar.css';
@import '../../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.css';
@import '../../node_modules/animate.css/animate.css';
@import '../../node_modules/select2/dist/css/select2.css';
@import '../../node_modules/codemirror/lib/codemirror.css';
@import '../../node_modules/nouislider/dist/nouislider.css';

// Bootstrap overrides
@import './sass/bootstrap-overrides/dropdown';
@import './sass/bootstrap-overrides/progress';
@import './sass/bootstrap-overrides/card';
@import './sass/bootstrap-overrides/buttons';
@import './sass/bootstrap-overrides/tables';
@import './sass/bootstrap-overrides/forms';
@import './sass/bootstrap-overrides/input-groups';
@import './sass/bootstrap-overrides/custom-forms';
@import './sass/bootstrap-overrides/pagination';
@import './sass/bootstrap-overrides/alerts';
@import './sass/bootstrap-overrides/close';
@import './sass/bootstrap-overrides/breadcrumbs';
@import './sass/bootstrap-overrides/collapse';
@import './sass/bootstrap-overrides/carousel';
@import './sass/bootstrap-overrides/modal';
@import './sass/bootstrap-overrides/popovers';
@import './sass/bootstrap-overrides/tabs';
@import './sass/bootstrap-overrides/tooltip';

// Core styles
@import './sass/inc/fonts';
@import './sass/inc/generics';
@import './sass/inc/utilities';
@import './sass/inc/base';
@import './sass/inc/list';
@import './sass/inc/layout';
@import './sass/inc/misc';
@import './sass/inc/header';
@import './sass/inc/footer';
@import './sass/inc/sidebar';
@import './sass/inc/chat';
@import './sass/inc/charts';
@import './sass/inc/toggle-switch';
@import './sass/inc/listview';
@import './sass/inc/toolbars';
@import './sass/inc/page-loader';
@import './sass/inc/profile';
@import './sass/inc/photos';
@import './sass/inc/contacts';
@import './sass/inc/groups';
@import './sass/inc/messages';
@import './sass/inc/pricing-table';
@import './sass/inc/invoice';
@import './sass/inc/login';
@import './sass/inc/todo';
@import './sass/inc/notes';
@import './sass/inc/theme';
@import './sass/inc/ie-warning';
@import './sass/inc/error';
@import './sass/inc/results';
@import './sass/inc/issue-tracker';
@import './sass/inc/team';
@import './sass/inc/blog';
@import './sass/inc/q-a';

// Widgets
@import './sass/widgets/past-days';
@import './sass/widgets/visitors';
@import './sass/widgets/pie-charts';
@import './sass/widgets/quick-stats';
@import './sass/widgets/photos';
@import './sass/widgets/ratings';
@import './sass/widgets/profile';
@import './sass/widgets/contact';
@import './sass/widgets/signups';
@import './sass/widgets/time';
@import './sass/widgets/search';

// Vendor overrides
@import './sass/vendor-overrides/flot';
@import './sass/vendor-overrides/sparklines';
@import './sass/vendor-overrides/select2';
@import './sass/vendor-overrides/dropzone';
@import './sass/vendor-overrides/nouislider';
@import './sass/vendor-overrides/easy-pie-charts';
@import './sass/vendor-overrides/data-tables';
@import './sass/vendor-overrides/flatpickr';
@import './sass/vendor-overrides/bootstrap-colorpicker';
@import './sass/vendor-overrides/trumbowyg';
@import './sass/vendor-overrides/fullcalendar';
@import './sass/vendor-overrides/sweetalert2';
@import './sass/vendor-overrides/lightgallery';
@import './sass/vendor-overrides/jquery.scrollbar';
@import './sass/vendor-overrides/Waves';
@import './sass/vendor-overrides/jqTree';
@import './sass/vendor-overrides/rateYo';
@import './sass/vendor-overrides/jquery-text-counter';

// Custom
@import './sass/elements/buttons';
@import './sass/elements/menus';
@import './sass/elements/codemirror';
@import './sass/elements/links';
@import './sass/elements/tables';
@import './sass/elements/forms';
@import './sass/elements/lists';
@import './sass/elements/actions';
@import './sass/elements/typography';
@import './sass/elements/flatpickr';
@import './sass/elements/image-map-pro';
@import './sass/elements/spinner';

// Overrides
@import './sass/general_overrides.scss';