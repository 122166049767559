body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .main-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > section.content {
      flex-grow: 1;
    }

    .footer {
      padding: 2rem 1rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      justify-content: space-evenly;
      background-color: $gray-300;

      .logo {
        max-width: 90%;
        width: 125px;
      }
    }
  }
}